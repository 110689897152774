<template>
  <div class="exploreMore">
    <div class="header">
      <h1 class="text-center" style="margin: 0">
        {{ $t("landingExploreHeading") }}
      </h1>
    </div>

    <div class="subheading">
      <h4 class="text-center" style="font-weight: normal">
        {{ $t("landingExploreSubheading") }}
      </h4>
    </div>
    <v-container>
      <v-row no-gutters="">
        <v-col v-for="item in items" :key="item.id" no-gutters cols="12" sm="6" md="4">
          <v-row class="pa-6" justify="center">
            <div class="alignCard">
              <h3 class="mb-4" style="min-height: 72px">{{ item.step }}</h3>
              <div style="display: flex; align-items: center; justify-content: center">
                <v-img class="stepIcon white--text" :src="item.src" height="150px" width="150px" contain> </v-img>
              </div>

              <!-- <v-row>
                <h3>{{ item.title }}</h3>
              </v-row> -->
              <v-row
                ><h5 style="font-weight: normal" class="px-3">{{ item.body }}</h5></v-row
              >
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="d-flex justify-center">
      <v-btn rounded="" depressed x-large width="250px" href="https://tectechain.io">{{
        this.$t("landingExploreGetStarted")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExploreMore",
  data: () => ({
    show: false,
    onboarding: 0
  }),
  computed: {
    items() {
      return [
        {
          id: 1,
          step: this.$t("landingExplore1.title"),
          title: this.$t("landingExplore1.subtitle"),
          body: this.$t("landingExplore1.subtitle"),
          src: require("@/assets/i-con/Consulting.svg")
        },
        {
          id: 2,
          step: this.$t("landingExplore2.title"),
          title: this.$t("landingExplore2.subtitle"),
          body: this.$t("landingExplore2.subtitle"),
          src: require("@/assets/i-con/Technical Development.svg")
        },
        {
          id: 3,
          step: this.$t("landingExplore3.title"),
          title: this.$t("landingExplore3.subtitle"),
          body: this.$t("landingExplore3.subtitle"),
          src: require("@/assets/i-con/Minting.svg")
        },
        // {
        //   id: 4,
        //   step: this.$t("landingExplore4.title"),
        //   title: this.$t("landingExplore4.subtitle"),
        //   body: this.$t("landingExplore4.subtitle"),
        //   src: require("@/assets/i-con/Crypto and NFT Marketing.svg")
        // }
      ];
    }
  }
};
</script>
