<template>
  <div>
    <div class="heroBlock">
      <div class="background">
        <v-carousel hide-delimiters show-arrows-on-hover v-model="carouselPosition" height="100%">
          <v-carousel-item v-for="(slide, i) in banner" :key="i">
            <v-img @load="startTimer" :src="slide.src" :lazy-src="slide.lazy" height="100%" eager />
          </v-carousel-item>
        </v-carousel>
      </div>

      <div class="textBlock">
        <div class="textWindow">
          <div class="d-flex">
            <div class="copyrightLogo">
              <img :src="content.copyrightLogo" />
            </div>
            <div class="px-15 pb-5 d-flex flex-column justify-space-between">
               <div style="">
                <v-row v-model="carouselPosition">
                  <div>
                    <h1 style="max-width: 600px; padding-bottom: 40px">{{ banner[carouselPosition].heading }}</h1>
                  </div>
                </v-row>
                <!--<v-row>
                  <ul class="subheading">
                    <li v-for="(item, id) in banner[carouselPosition].subheading" :key="id">
                      <h3>{{ item }}</h3>
                    </li>
                  </ul>
                </v-row> -->
              </div>
              <v-row>
                <v-btn depressed rounded x-large color="primary" to="/signup" width="150px">
                  {{ this.$t("signup") }}
                </v-btn>
              </v-row>
              <v-row>
                <div class="d-flex justify-space-between" style="width: 150px">
                  <v-btn size="60px" fab dark color="white" depressed @click="prevPage">
                    <v-icon color="primary" size="60px">mdi-menu-left </v-icon>
                  </v-btn>
                  <v-btn size="60px" fab dark color="white" depressed @click="nextPage">
                    <v-icon color="primary" size="60px">mdi-menu-right </v-icon>
                  </v-btn>
                </div>
              </v-row>
            </div>
          </div>
          <div class="heroBanner">
            <h1 style="text-align: center">
              {{ this.$t("landingHeroTitle") }}
            </h1>
            <p>
              {{ this.$t("landingHeroSubtitle") }}
            </p>
          </div>
        </div>
      </div>
      <meta name="description" :content="banner[0].heading" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  data() {
    return {
      content: {
        copyrightLogo: require("../../assets/images/Group 3.svg")
      },
      carouselPosition: 0,
      timer: null
    };
  },
  methods: {
    startTimer() {
      this.timer = setTimeout(() => this.carouselPosition++, 6000);
    },
    prevPage() {
      clearInterval(this.timer);
      this.startTimer();
      this.carouselPosition--;
    },
    nextPage() {
      clearInterval(this.timer);
      this.startTimer();
      this.carouselPosition++;
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    }
  },
  watch: {
    carouselPosition(value) {
      if (value >= this.banner.length) {
        this.carouselPosition = 0;
      }
      if (value < 0) {
        this.carouselPosition = this.banner.length - 1;
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    banner() {
      return [
        {
          src: require("@/assets/Banner/shubham-dhage-_rZnChsIFuQ-unsplash.jpg"),
          lazy: require(/* webpackPreload: true */ "@/assets/Banner/shubham-dhage-_rZnChsIFuQ-unsplash.jpg"),
          heading: this.$t("landingHeroCorusel1"),
          subheading: this.translateArray("landingHeroText1")
        },
        {
          src: require("@/assets/Banner/pickawood-gf8e6XvG_3E-unsplash.jpg"),
          lazy: require(/* webpackPreload: true */ "@/assets/Banner/pickawood-gf8e6XvG_3E-unsplash.jpg"),
          heading: this.$t("landingHeroCorusel2"),
          subheading: this.translateArray("landingHeroText2")
        },
        {
          src: require("../../assets/Banner/abbie-bernet-04X58d_hHv8-unsplash.jpg"),
          lazy: require(/* webpackPreload: true */ "../../assets/Banner/abbie-bernet-04X58d_hHv8-unsplash.jpg"),
          heading: this.$t("landingHeroCorusel3"),
          subheading: this.translateArray("landingHeroText3")
        },
        {
          src: require("@/assets/Banner/dan-nelson-ah-HeguOe9k-unsplash (1).jpg"),
          lazy: require("@/assets/Banner/dan-nelson-ah-HeguOe9k-unsplash (1).jpg"),
          heading: this.$t("landingHeroCorusel4"),
          subheading: this.translateArray("landingHeroText4")
        },
        {
          src: require("@/assets/Banner/antenna-jqh0GEvuNBY-unsplash.jpg"),
          lazy: require("@/assets/Banner/antenna-jqh0GEvuNBY-unsplash.jpg"),
          heading: this.$t("landingHeroCorusel5"),
          subheading: this.translateArray("landingHeroText5")
        }
      ];
    },
    subheading() {
      return this.translateArray("landingHeroText".concat(this.carouselPosition));
    }
  }
};
</script>
