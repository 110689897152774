<template>
  <div>
    <div v-if="items.length > 0 && !loading" id="news">
      <v-window v-model="onboarding">
        <v-window-item
          v-for="item in items"
          :key="`card-${item.infoid}`"
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <div class="latestPostBlock">
            <div class="d-flex">
              <div class="imageBox">
                <v-lazy min-height="750px">
                  <image-from-file-descriptor
                    height="750px"
                    :fileDescriptor="item.landingInfo.coverImage"
                    :borderRadius="false"
                    :thumbnail="true"
                  />
                </v-lazy>
              </div>
              <div class="hidden-xs-only" style="width: 35%; height: 750px">
                <image-from-file-descriptor
                  height="750px"
                  :fileDescriptor="item.landingInfo.coverImage"
                  :borderRadius="false"
                  :thumbnail="true"
                  :opacity="0.15"
                />
              </div>
            </div>
            <!-- <div class="hidden-sm-and-up" style="height: 600px">
              <image-from-file-descriptor
                :fileDescriptor="item.landingInfo.coverImage"
                :key="item.infoid"
                :borderRadius="false"
              />
            </div> -->
            <!-- <v-img
            :lazy-src="item.src"
            width="30%"
            position="center right"
          ></v-img> -->

            <div class="newsBox">
              <div class="newsCard d-flex flex-column">
                <h1 style="color: white">{{ item.title[$i18n.locale] }}</h1>
                <h5 style="color: white; margin-bottom: 10px">
                  {{ new Date(item.createdAt).toISOString().substr(0, 10) }}
                </h5>

                <div class="flex-grow-1 noscroll fade" style="overflow: hidden">
                  <p style="line-height: 1.2em">{{ sanitizeWithSpace(item.body[$i18n.locale]) }}</p>
                </div>

                <div style="margin-top: 35px; display: flex; justify-content: space-between; flex-wrap: wrap">
                  <div class="mt-3">
                    <v-btn depressed fab rounded="" @click="prev">
                      <v-icon color="#63a3c3" size="60px">mdi-menu-left</v-icon>
                    </v-btn>
                    <v-btn depressed fab @click="next" :style="{ marginLeft: $screen.width > 900 ? '34px' : '5px' }">
                      <v-icon color="#63a3c3" size="60px">mdi-menu-right</v-icon>
                    </v-btn>
                  </div>
                  <div class="mt-3">
                    <v-btn depressed rounded height="56px" @click="toFullNews(item.infoid)"
                      ><h5 class="sb" style="color: #63a3c3">{{ $t("landingLearnMore") }}</h5></v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </div>
    <div id="news" style="display: flex; align-items: center; justify-content: center" v-if="loading">
      <v-progress-circular size="60" color="primary" width="3" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import { getLandingInfo } from "../../services/workService";

export default {
  components: { ImageFromFileDescriptor: () => import("../Common/ImageFromFileDescriptor.vue") },
  name: "LatestPost",
  data: () => ({
    show: false,
    items: [],
    onboarding: 0,
    success: false,
    loading: true
  }),

  async created() {
    this.loading = true;
    const landingInfo = await getLandingInfo(this.$axios, config);

    if (landingInfo.status === 200) {
      this.success = true;
      this.loading = false;
      this.items = landingInfo.data
        .sort((a, b) => {
          return b.createdAt - a.createdAt;
        })
        .map((elem) => {
          elem.title = elem.landingInfo.title;
          elem.body = elem.landingInfo.body;
          return elem;
        });
    } else {
      this.success = false;
    }
  },
  methods: {
    next() {
      this.onboarding = this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    toFullNews(infoid) {
      const route = { path: `/news/${infoid}` };
      this.$router.push(route);
    },
    sanitizeWithSpace(body) {
      // body = body.replace(/>/g, "> \\");
      body = this.$sanitize(body, { allowedTags: [] });
      // body = body.replace(/\\/g, "&nbsp");
      return body;
    }
  },
  computed: {
    length() {
      return this.items.length;
    }
  }
};
</script>
