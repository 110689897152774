<template>
  <v-lazy>
    <v-row class="d-flex flex-row-reverse justify-center" style="width: 100%; max-width: 1300px">
      <v-col cols="12" sm="6" class="d-flex flex-column align-center">
        <v-img
          :src="src"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
          width="100%"
          style="border-top-right-radius: 48px"
        >
          <h1>{{ this.$t("landingFAQ") }}</h1>
        </v-img>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex flex-column align-center justify-space-between">
        <div v-for="(item, i) in items" :key="i" style="width: 100%; margin: 0px 0 20px 0">
          <v-dialog v-model="item.dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <div class="faqBubble" v-bind="attrs" v-on="on">
                <img :src="item.icon" color="white" width="30px" />
                <faq-text :path="item.title" tag="h4" />
                <div class="faqTriangle"><v-icon>mdi-menu-right</v-icon></div>
              </div>
            </template>
            <v-card max-width="600px" class="pa-10">
              <faq-text :path="item.title" tag="h3" class="mb-5" id="faqTitle" />
              <faq-text :path="item.body" tag="p" />
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-btn rounded color="primary" depressed large @click="viewAllFAQ">
            <span style="text-transform: uppercase">{{ $t("landingFAQViewAll") }}</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-lazy>
</template>

<script>
import faqText from "../Common/faqText.vue";
export default {
  components: { faqText },
  name: "FAQ",
  data: () => ({
    show: false,
    src: require("../../assets/images/faq_img.png")
  }),
  computed: {
    items() {
      return [
        {
          id: 1,
          title: "faq.question.sectionA.q003",
          body: "faq.answer.sectionA.q003",
          icon: require("../../assets/icon_pack/24px/icon/faq/bag.svg")
        },
        {
          id: 2,
          title: "faq.question.sectionA.q002",
          body: "faq.answer.sectionA.q002",
          icon: require("../../assets/icon_pack/24px/icon/faq/payment.svg")
        },
        // {
        //   id: 3,
        //   title: "faq.question.sectionG.q004",
        //   body: "faq.answer.sectionG.q004",
        //   icon: require("../../assets/icon_pack/24px/icon/faq/refund.svg")
        // },
        {
          id: 4,
          title: "faq.question.sectionC.q007",
          body: "faq.answer.sectionC.q007",
          icon: require("../../assets/icon_pack/24px/icon/faq/themes.svg")
        },
        // {
        //   id: 5,
        //   title: "faq.question.sectionG.q003",
        //   body: "faq.answer.sectionG.q003",
        //   icon: require("../../assets/icon_pack/24px/icon/faq/files.svg")
        // },
        // {
        //   id: 6,
        //   title: "faq.question.sectionD.q010",
        //   body: "faq.answer.sectionD.q010",
        //   icon: require("../../assets/icon_pack/24px/icon/faq/visa.svg")
        // }
      ];
    }
  },
  methods: {
    viewAllFAQ() {
      this.$store.commit("updateCurrentPage", 2);
      this.$router.push("/questions");
    }
  }
};
</script>
