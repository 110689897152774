<template>
  <div class="carouselBlock">
    <h1>{{ $t("landingHeroHeading") }}</h1>
    <div class="d-flex justify-center" style="max-width: 1368px; width: 100%; padding: 24px" v-if="items.length > 0">
      <div v-if="!loading">
        <v-slide-group id="carouselSlider" v-model="carouselPosition" style="height: 500px" :mobile-breakpoint="600">
          <v-slide-item v-for="item in items" :key="item.workid">
            <bazaar-carousel-item :item="item" class="pa-3" />
          </v-slide-item>
          <template v-slot:prev>
            <v-btn class="arrow mr-10" size="60px" fab dark color="primary" :ripple="false" depressed>
              <v-icon color="white" size="60px">mdi-menu-left </v-icon>
            </v-btn>
          </template>
          <template v-slot:next>
            <v-btn class="arrow ml-10" size="60px" fab dark color="primary" depressed :ripple="false">
              <v-icon color="white" size="60px">mdi-menu-right </v-icon>
            </v-btn>
          </template>
        </v-slide-group>
        <div id="carouselGrid">
          <v-row>
            <v-col cols="6" v-for="i in [0, 1, 2, 3]" :key="i">
              <bazaar-carousel-item :item="items[gridIndex * 4 + i]" v-if="items[gridIndex * 4 + i]" />
            </v-col>
          </v-row>
          <div style="display: flex; justify-content: center; width: 100%">
            <div style="width: 100%">
              <v-pagination
                class="elevation-0 mt-4"
                circle
                v-model="gridIndex"
                :length="Math.floor(items.length / 4) - 1"
                flat
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 450px" class="d-flex align-center" v-else>
        <v-progress-circular indeterminate large color="primary"></v-progress-circular>
      </div>
    </div>
    <v-btn depressed rounded x-large color="primary" to="/platform/bazaar" width="250px" style="margin-top: 32px">
      <h5 style="text-transform: uppercase; margin: 0">{{ $t("landingWorkSignup") }}</h5>
    </v-btn>
  </div>
</template>
<script>
import config from "@/config";
import { displayLandingWorks } from "../../services/workService";
import BazaarCarouselItem from "./BazaarCarouselItem.vue";
export default {
  components: {
    BazaarCarouselItem
  },
  data: () => ({
    carouselPosition: 0,
    bazaarList: [],
    loading: false,
    expandGrid: false,
    gridIndex: 1
  }),
  created() {
    this.loading = true;
    displayLandingWorks(this.$axios, config).then((res) => {
      if (res.status === 200) {
        this.bazaarList = res.data;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  },
  computed: {
    items() {
      let sample = this.shuffle(this.bazaarList);
      return sample;
    },
    displayItems() {
      if (this.items.length === 0 || !this.items) {
        return [];
      }

      return this.items.slice(this.gridIndex * 4, (this.gridIndex + 1) * 4);
    }
  },
  methods: {
    gridNext() {
      if (this.gridIndex > this.items.length / 4) {
        this.gridIndex = 0;
      } else {
        this.gridIndex = this.gridIndex + 1;
      }
    },
    gridPrev() {
      if (this.gridIndex <= 0) {
        this.gridIndex = Math.floor(this.items.length / 4) - 1;
      } else {
        this.gridIndex = this.gridIndex - 1;
      }
    },
    shuffle: function (array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }
  }
};
</script>
