<template>
  <div>
    <HomeHero id="heroBlock" />
    <!-- <div>
      <event-landing-gallery />
    </div>-->
    <div>
      <BazaarCarousel />
    </div>
    <div id="cases">
      <News />
    </div>

    <FAQ id="FAQ" />

    <div id="pricingTable">
      <Pricing />
    </div>
    <div id="service">
      <ExploreMore />
    </div>
  </div>
</template>

<script>
import Landing from "../layouts/Landing";
import HomeHero from "../components/Home/HomeHero";
import News from "../components/Home/News";
import ExploreMore from "../components/Home/ExploreMore";
import FAQ from "../components/Home/FAQ";
import Pricing from "../components/Home/Pricing";
import BazaarCarousel from "../components/Home/BazaarCarousel.vue";
// import EventLandingGallery from "../components/Home/EventLandingGallery.vue";

export default {
  name: "Home",
  components: {
    HomeHero,
    News,
    ExploreMore,
    FAQ,
    Pricing,
    BazaarCarousel,
    // EventLandingGallery
  },
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>

<style scoped></style>
