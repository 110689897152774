<template>
  <div>
    <div class="tint"></div>
    <div>
      <v-container>
        <div class="header">
          <h1 class="text-center white--text">
            {{ $t("landingPricingHeading") }}
          </h1>
        </div>
        <p class="text-center white--text">
          {{ $t("landingPricingSubheading") }}
        </p>

        <v-row justify="center">
          <v-col v-for="item in items" :key="item.id" justify="center" align="center" cols="12" sm="6" md="4">
            <v-card outlined max-width="400px" min-width="250px" style="border-radius: 24px; height: 100%">
              <div class="pb-12 pt-4">
                <v-card-title class="justify-center secondary--text"><h1 v-html="item.price"></h1> </v-card-title>
                <v-card-subtitle class="pb-0 text-center">
                  <h4 style="font-weight: normal">{{ item.title }}</h4>
                </v-card-subtitle>
              </div>
              <hr />
              <v-container>
                <v-card-text class="text-center" style="color: #7e8085">
                  <li v-for="(features, i) in item.features" v-bind:key="i" style="list-style-type: none">
                    <div v-if="i === item.features.length - 1">
                      <h5 style="font-weight: normal; margin: 5px; line-height: 1.2">{{ features }}</h5>
                    </div>
                    <div v-else>
                      <h5 style="font-weight: normal; margin: 5px; line-height: 1.2">{{ features }}</h5>

                      <h3>+</h3>
                    </div>
                  </li>
                </v-card-text>
              </v-container>

              <v-btn
                rounded=""
                depressed
                color="primary"
                @click="toSignup(item.membership)"
                id="signupButton"
                style="width: 80%; padding: 24px; margin-bottom: 40px"
              >
                <h4 style="margin: 0">
                  {{ $t("landingPricingPremiumGetStarted") }}
                </h4>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

        <h3 class="text-center white--text" style="margin-top: 45px; margin-bottom: 45px">
          {{ $t("landingPricingText") }}
        </h3>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  data: () => ({
    background: require("../../assets/images/pricing_table_bg.png"),
    onboarding: 0
  }),
  computed: {
    items() {
      return [
        {
          id: 1,
          price: this.$t("landingPricingFree.heading"),
          title: this.$t("landingPricingFree.body"),
          features: this.$t("landingPricingFreeText").split("+"),
          route: "/",
          membership: "free",
          showAdditionalFeatures: false
        },
        {
          id: 2,
          price: this.$t("landingPricingPremium.heading"),
          title: this.$t("landingPricingPremium.body"),
          features: this.$t("landingPricingPremiumText").split("+"),
          additionalFeatures: ["Additional feature 1", "Additional feature 2"],
          route: "/",
          membership: "premium",
          showAdditionalFeatures: false
        }
      ];
    }
  },
  methods: {
    next() {
      this.onboarding = this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    toSignup(pref) {
      localStorage.setItem("membershipPreference", pref);
      this.$router.push("/signup").catch((e) => e);
    }
  }
};
</script>
